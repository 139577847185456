<template>
  <div>
    <div class="">
      <!-- <div class=" meta__modal-close text-end">
        <i class="far fa-times-circle" @click.prevent="isOpen = false;"></i>
      </div>   -->
      <slot name="close"></slot>
      <div class="p-2 d-flex mt-2 align-items-center justify-content-between">
        <span class="text-start">課程內容</span>
        <vue-ellipse-progress
          :progress="progress ? progress : 0"
          :size="30"
          :legend="false"
          :thickness="3"
          :color="'#4158d0'"
          :emptyThickness="3"
          :emptyColorFill="'#dde6f0'"
          :emptyColor="'#fff'">
          <div class="number" slot="legend-caption" ><i class="fas fa-flag course__ellipse-progress"></i></div>
        </vue-ellipse-progress>
      </div>
    </div>
    <div class="course__accorbox">
      <div class="course__accor">
        <div class="accordion" id="accordionExample" v-if="filteredchapters.length > 0">
          <div class="accordion-item" v-for="(item, i) in filteredchapters" :key="i">
            <h2 class="accordion-header" :id="`L${i}`">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${i}`" aria-expanded="true" :aria-controls="`collapse${i}`">
                {{ item.title }}
              </button>
            </h2>
            <div :id="`collapse${i}`" class="accordion-collapse collapse show" :aria-labelledby="`heading${i}`" data-bs-parent="#accordionExample">
              <div class="accordion-body p-2">
                <ul>
                  <li class="course__accor__list d-flex justify-content-between"
                    v-for="(sub, idx) in item.chaperItem" :key="`${i}-${idx}`"
                    @click.prevent="changeVideo(sub.chapterId, `${i}-${idx}`, sub.videoId, sub.videoUrl, sub.content, sub.videoStatus)"
                    :class="{'active': nowVid == `${i}-${idx}`}"
                    >
                    <!-- {{ nowVid }}
                    {{ `${i}-${idx}` }} -->
                    <template v-if="!checkComplete(sub.chapterId)">
                      <i class="far fa-circle me-2 d-inline-block"></i>
                    </template>
                    <template v-if="checkComplete(sub.chapterId)">
                      <i class="fas fa-check-circle me-2 d-inline-block" ></i>
                    </template>
                     <!-- {{sub.completed}}  -->
                    <span class="title" style="flex: 1 1 0%;">{{ sub.title }}</span>
                    <span class="time">{{ formatTime(sub.duration.min) }} : {{ formatTime(sub.duration.sec) }}</span>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { VueEllipseProgress } from 'vue-ellipse-progress';

export default {
  name: 'Meta_List',
  props: ['vid', 'chapters', 'nowChapter', 'nowSection', 'totalSection', 'endChapterId'],
  data() {
    return {
      nowVid: '0-0',
      completed: 0,
      arr: [],
      filteredchapters: [
        {
          title: '',
          chaperItem: [
            {
              chapterId: '',
              completed: true,
              title: '',
              videoId: 37,
              videoUrl: 'https://player.vimeo.com/video/716238910?h=ab5dc94a4f',
              videoStatus: 'available',
              lock: true,
              content: '<h1>H1 CAPTION</h1><h2>H2 CAPTION</h2><p>Content...</p><p><strong>090011</strong></p><p>New line.</p><h1>H1 CAPTION</h1><h2>H2 CAPTION</h2><p>Content...</p><p><br></p><p>New line.</p>',
              duration: {
                hour: 0,
                min: 0,
                sec: 11,
              },
            },
          ],
        },
      ],
    };
  },
  components: { VueEllipseProgress },
  methods: {
    changeVideo(chapterId, nowChapter, videoId, url, content, status) {
      this.$emit('changeVideo', {
        chapterId, nowChapter, videoId, url, content, status,
      });
      this.nowVid = nowChapter;
    },
    formatTime(num) {
      return num < 10 ? `0${num}` : num;
    },
    text(bool) {
      return bool ? 'true' : 'false';
    },
    checkComplete(item) {
      if (this.endChapterId.indexOf(item) >= 0) {
        return true;
      }
      return false;
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  watch: {
    nowSection(newVal, oldVal) {
      this.nowVid = `${this.nowChapter}-${this.nowSection}`;
    },
    nowChapter(newVal, oldVal) {
      this.nowVid = `${this.nowChapter}-${this.nowSection}`;
    },
    chapters: {
      handler(newVal, oldVal) {
        this.filteredchapters = newVal;
      },
    },
    completed() {
      this.progress = (this.completed / this.totalSection * 100);
    },
    nowVid() {

    },
  },
  computed: {
    test() {
      const arr = [...this.filteredchapters];
      arr.forEach((el, i) => {
        el.chaperItem.forEach((element, n) => {
          if (element.completed == true) {
            element.completed = '完成';
          } else {
            element.completed = '未完成';
          }
        });
      });
      return arr;
    },
    progress() {
      return (this.endChapterId.length / this.totalSection * 100);
    },
  },
  created() {
    this.getUserAuthFromLocal();
    this.nowVid = `${this.nowChapter}-${this.nowSection}`;
  },
};

</script>
