<template>
  <div>
    <div class="course">
      <div class="course__hero course__hero-main">
        <h1 class="course__title">{{ course.courseName }}</h1>
        <!-- {{ nowChapter }} - {{ nowSection }} -->
        <div class="container" style="background-color: black;">
          <div class="course-intro__video d-flex align-items-center justify-content-center mt-5 row course-intro__main">
            <div class="video col-12 col-md-7 p-0 p-2 p-md-0">
              <div class="course__loading d-flex align-items-center justify-content-center" v-if="videoStatus !== 'available'">
                <span>影片處理中，請稍後再觀看</span>
                <vue-vimeo-player ref="player"
                  :video-id="splitVid"
                  :video-url="`${vid}?&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
                  :player-height="videoHeight"
                  :player-width="videoWidth"
                  :options="{ responsive: true }"
                  @pause="setTime" @ended="setTime(data);setChapterComplete();next()"
                  @loaded="console1"
                  ></vue-vimeo-player>
              </div>
              <div class="video__wrapper" v-if="course && videoStatus == 'available'" style="width: 100%;height: 0;">
                <vue-vimeo-player ref="player"
                  :video-id="splitVid"
                  :video-url="`${vid}?&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
                  :player-height="videoHeight"
                  :player-width="videoWidth"
                  :options="{ responsive: true }"
                  @pause="setTime" @ended="setTime(data);setChapterComplete();next()"
                  @loaded="console1"
                  ></vue-vimeo-player>
              </div>
            </div>
            <div class="meta__mainList p-3 col-12 col-md-5 d-none d-md-block">
              <MetaList
                @changeVideo="changeVideo"
                :vid="vid"
                :chapters="chapters"
                :nowChapter="nowChapter"
                :nowSection="nowSection"
                :totalSection="totalSection"
                :endChapterId="endChapterId"
                ></MetaList>
            </div>
            <div class="row py-2 py-md-3" style="background-color: white;z-index: 3;">
              <div class="col-6 col-md-4  d-flex justify-content-center align-items-center py-2 py-md-3 course__control" @click="prevAndNext(-1)">
                <i class="fas fa-chevron-left me-3"
                  ></i>
                <p>上一堂課</p>
              </div>
              <div class="col-6 col-md-4  d-flex justify-content-center align-items-center py-2 py-md-3 course__control" @click="prevAndNext(1)">
                <p>下一堂課</p>
                <i class="fas fa-chevron-right ms-3"
                  ></i>
              </div>
              <div class="col-12 col-md-4 d-flex justify-content-center align-items-center py-3 course__track">
                <input type="checkbox" id="switch" v-model="track" class="course__track--input" /><label for="switch" class="course__track--toggle">Toggle</label>
                <p class="ms-2">紀錄影片觀看進度</p>
              </div>
            </div>

            <div class="meta__btn p-3 mt-4 col-12 d-block d-md-none" @click.prevent="isOpen = !isOpen">
              課程內容 >
            </div>

            <div class="meta__mainList meta__modal p-3 col-12 d-md-none" v-show="isOpen">
              <MetaList
                @changeVideo="changeVideo"
                :vid="vid"
                :chapters="chapters"
                :nowChapter="nowChapter"
                :nowSection="nowSection"
                :totalSection="totalSection"
                :endChapterId="endChapterId">
                <template v-slot:close>
                  <div class="meta__modal-close text-end">
                    <i class="far fa-times-circle" @click.prevent="isOpen = false;"></i>
                  </div>
                </template>
              </MetaList>
            </div>
          </div>
        </div>

      </div>
      <!-- <ul class="nav nav-tabs nav-tabs-bordered tablist">
        <li class="nav-item">
          <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#course-overview">概覽</button>
        </li>

        <li class="nav-item">
          <button class="nav-link" data-bs-toggle="tab" data-bs-target="#course-">編輯</button>
        </li>

        <li class="nav-item">
          <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings">設定</button>
        </li>

        <li class="nav-item">
          <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">更改密碼</button>
        </li>
      </ul> -->

      <div class="container">
        <div class="row">
          <!-- 左側 -->
          <div class="col-12 col-md-9 text-start">
            <h3 class="course__subtitle pt-4">關於此單元</h3>
            <div class="mb-3" v-html="chapterContent"></div>
            <ul class="nav nav-tabs nav-tabs-bordered tablist pt-4 course__tablist">
                <li class="nav-item">
                  <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">課程簡介</button>
                </li>

                <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">課程評價</button>
                </li>

                <!-- <li class="nav-item">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings">問題討論</button>
                </li> -->

              </ul>
              <div class="tab-content pt-2">
                <div class="tab-pane fade show active profile-overview" id="profile-overview">
                  <div class="course__about py-3 py-md-3 mt-3">
                    <h3 class="course__subtitle">關於課程</h3>
                    <div class="row">
                      <div class="col-12 col-md-6 d-flex align-items-center mb-3">
                        <img src="../assets/images/users-100.png" alt="">
                        <div class="d-flex flex-column">
                          <span class="mb-2 text-muted">授課教師</span>
                          <span>{{ course.authorName }}</span>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 d-flex align-items-center mb-3">
                        <img src="../assets/images/clock.svg" alt="">
                        <div class="d-flex flex-column">
                          <span class="mb-2 text-muted">單元數</span>
                          <span v-if="course">{{ totalChapter }} 章節 {{ totalSection }} 單元 (約 {{course.duration.hour}} 小時 {{course.duration.min}} 分)</span>
                         
                        </div>
                      </div>
                      <div class="col-12 col-md-6 d-flex align-items-center mb-3">
                        <img src="../assets/images/skills.png" alt="" class="me-1">
                        <div class="d-flex flex-column">
                          <span class="mb-2 text-muted">課程標籤</span>
                          <div>
                            <span v-for="tag in course.courseHashtag" :key="tag" class="badge rounded-pill course__tag">{{ tag }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="course__about py-3 py-md-3 mt-3" v-if="course.files.length > 0">
                    <h3 class="course__subtitle">課程範例檔案</h3>
                    <div class="row">
                      <div class="col-12 col-md-6 d-flex align-items-center mb-3" v-for="(item, i) in course.files" :key="i">
                        <img src="../assets/images/file-upload.svg" alt="">
                        <div class="d-flex flex-column">
                          <p class="mb-1">{{ item.fileName }}</p>
                          <a class="mb-2 text-muted" :href="item.link" target="_blank">壓縮檔案</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="course__desc py-3 py-md-3">
                    <h3 class="course__subtitle">課程詳情</h3>
                    <div class="course__introtxt" v-html="decodeEntity(course.courseDescription).split('\n').join('<br>')"></div>
                  </div>
                </div>
                <div class="tab-pane fade profile-edit pt-3" id="profile-edit">
                  <div class="course__review py-3 py-md-5">
                    <div class="d-flex justify-content-between align-items-center">
                      <h3 class="course__subtitle">課程評論</h3>
                      <div class="d-flex align-items-center">
                        <a class="course__review__newest me-5 mb-3" v-if="nowPage > 1" @click.prevent="nowPage = 1; getComments()">最新</a>
                        <div v-if="isLoggedin">
                          <button class="btn btn-main mb-3" data-bs-target="#evaluationModal" data-bs-toggle="modal" type="button" v-if="!commented">發表評論</button>
                          <button class="btn btn-main mb-3" data-bs-target="#evaluationModal" data-bs-toggle="modal" type="button" v-if="commented">修改評論</button>
                        </div>
                      </div>
                    </div>
                    <template v-if="commentLoading== true">
                      <div>
                        <i class="fas fa-spinner course__spinner"></i>
                      </div>
                    </template>
                    <template v-if="commentLoading== false">
                      <div class="course__review__card">
                        <p v-if="comments.length < 1">尚無評論</p>
                        <div class="card" style="border-radius: 0rem;"  v-if="comments.length >= 1">

                          <div v-for="(item, i) in comments" :key="item.id">
                            <div class="card-body p-4 text-start d-flex justify-content-start align-items-sm-center  flex-column flex-sm-row">
                              <!-- sm and larger -->
                              <div class="card-photo ms-3 me-5 d-none d-sm-block">
                                <div class="card-imgfr mb-2">
                                  <img :src="item.avatar == 'NotSet' ? '/static/images/user.png': `${item.avatar}`" alt="" class="rounded-circle card-header p-0">
                                </div>
                                <p class="course__review__user mb-2">{{ item.userName }}</p>
                                <span>
                                  <time class="text-muted">{{ item.updateTime.split(' ')[0] }}</time>
                                </span>
                              </div>
                              <!-- sm and smaller -->
                              <div class="card-photo me-5 d-block d-sm-none">
                                <div class="d-flex">
                                  <div class="card-imgfr mb-2 me-4">
                                    <img :src="item.avatar == 'NotSet' ? '/static/images/user.png': `${item.avatar}`" alt="" class="rounded-circle card-header p-0">
                                  </div>
                                  <div class="">
                                    <p class="course__review__user mb-2">{{ item.userName }}</p>
                                    <span>
                                      <time class="text-muted">{{ item.updateTime.split(' ')[0] }}</time>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="card-right">
                                <div class="courses__rating mb-3">
                                  <star-rating
                                    :rating="item.starRating"
                                    :show-rating="false"
                                    :rounded-corners="true"
                                    :star-size="20"
                                    :read-only="true"
                                    :increment="0.5"
                                    :border-width="0" />
                                </div>
                                <strong class="fw-bold mb-3 d-block">{{ item.title }}</strong>
                                <p style="line-height: 1.4rem;">{{ item.comment}}</p>

                              </div>
                            </div>
                            <hr class="my-0 mx-auto course__review__hr" :class="{'d-none': i === comments.length - 1}">
                          </div>

                        </div>
                      </div>
                      <div class="course__review__pages mt-3 mb-2 d-flex justify-content-center">
                        <i class="fas fa-chevron-left me-3 course__review__page--switch"
                          v-if="nowPage > 1" @click.prevent="switchPage(-1)"></i>
                        <i class="fas fa-chevron-right ms-3 course__review__page--switch"
                          v-if="nowPage < totalPages" @click.prevent="switchPage(1)"></i>
                      </div>
                    </template>

                  </div>
                </div>

                <!-- <div class="tab-pane fade pt-3 profile-settings" id="profile-settings">
                  <div class="course__review py-3 py-md-5">
                    <div class="d-flex justify-content-between align-items-center">
                      <h3 class="course__subtitle">問題討論</h3>
                      <button class="btn btn-main mb-3" data-bs-target="#exampleModal" data-bs-toggle="modal" type="button">發表留言</button>
                    </div>
                    <div class="course__review__card">
                      <div class="card" style="border-radius: 0rem;">
                        <div v-for="(item, i) in discussion" :key="item.id">
                          <div class="card-body p-4 pb-2 text-start d-flex align-items-center">
                            <div class="card-photo me-4">
                              <div class="card-imgfr mb-2">
                                <img src="../assets/images/profile-img.jpg" alt="" class="rounded-circle card-header p-0">
                              </div>
                              <p class="course__review__user mb-2">{{ item.username }}</p>
                              <span>
                                <time class="text-muted">{{ item.time }}</time>
                              </span>
                            </div>
                            <div class="card-right">
                              <strong class="fw-bold smb-3 d-block" @click.prevent="showReply(item.id)">{{ item.title }}</strong>
                              <p>{{ item.content }}</p>
                            </div>
                          </div>
                          <div class="card-body course__review__replies" v-if="showNum === item.id">
                            <div class="" v-for="res in item.response" :key="res.id">
                              <div class="card-body py-3 text-start d-flex align-items-center">
                                <div class="card-photo me-4">
                                  <div class="card-imgfr mb-2">
                                    <img src="../assets/images/profile-img.jpg" alt="" class="rounded-circle card-header p-0">
                                  </div>
                                  <p class="course__review__user mb-2">{{ res.username }}</p>
                                  <span>
                                    <time class="text-muted">{{ res.time }}</time>
                                  </span>
                                </div>
                                <div class="card-right">
                                  <p>{{ res.content }}</p>
                                </div>
                              </div>
                            </div>
                            <input type="text" name="" id="" class="p-2 course__review__reply" style="width: 100%;" placeholder="留言" v-if="showNum === item.id" v-model="tempReply">
                            <button class="mt-2 btn btn-main" @click.prevent="addRes">送出</button>
                          </div>
                          <hr class="my-0 mx-auto course__review__hr" :class="{'d-none': i === discussion.length - 1}">
                        </div>

                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
          </div>
          <!-- 右側 -->
          <div class="col-12 col-md-3">
          </div>

        </div>
      </div>
      <section class="container-fluid">
        <div class="container position-relative">
          <h2 class="course__related mb-5">相關課程</h2>
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="item in recommendData" :key="item.id">
              <CourseCard :course="item"></CourseCard>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev-1 swiper-btn d-flex justify-content-center align-items-center" slot="button-prev"> &lt; </div>
          <div class="swiper-button-next-1 swiper-btn d-flex justify-content-center align-items-center" slot="button-next"> &gt; </div>
        </div>
      </section>

      <div class="modal fade course__qa" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <h3 class="text-start fw-bold mb-2" style="font-size: 1.2rem;">標題</h3>
              <input type="text" placeholder="e.g.關於課程" class="course__qa__input" v-model="title">
              <quill-editor class="ql-editor" v-model="content"
                ref="myQuillEditor"
                style="height: 300px;"
                :options="editorOption"
                @change="onEditorChange($event)"
              >
              </quill-editor>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
              <button type="button" class="btn btn-main">送出</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade course__qa" id="evaluationModal" tabindex="-1" aria-labelledby="evaluationModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="text-start mb-3">課程評分</div>
              <div class="courses__rating mb-3 text-start">
                <star-rating
                  v-model="c_rating"
                  :show-rating="false"
                  :rounded-corners="true"
                  :star-size="25"
                  :increment="0.5"
                  :border-width="0" />
              </div>
              <h3 class="text-start fw-bold mb-2 evaluation__title" style="font-size: 1.2rem;">標題</h3>
              <input type="text" placeholder="e.g.關於課程" class="course__qa__input" v-model="c_title">
              <div class="text-start mt-3">評價內容</div>
              <textarea class="evaluation__desc mt-3" name="description" id="description" rows="20" v-model="c_description"></textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
              <button type="button" class="btn btn-main" data-bs-dismiss="modal" @click.prevent="submit">送出</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { mapGetters, mapActions } from 'vuex';
import EvaluationModal from '../components/EvaluationModal';
import MetaList from '../components/Meta_List';
import CourseCard from '../components/CourseCard';
import 'swiper/css/swiper.css';

const Swal = require('sweetalert2');

// [{
//   id: 1,
//   img: '',
//   title: 'test title',
//   content: 'content',
//   time: '2022/5/20',
//   username: 'qq',
//   response: [
//     {
//       id: 1-1, 
//       img: '',
//       title: 'test title',
//       content: 'content 1',
//       time: '2022/5/20',
//       username: 'qq',
//     },
//   ]
// }]

export default {
  name: 'Course_main',
  components: {
    Swiper,
    SwiperSlide,
    CourseCard,
    MetaList,
    EvaluationModal,
    StarRating,
    vueVimeoPlayer,
  },
  data() {
    return {
      arr: [],
      endChapterId: [],
      data: [],
      videoWidth: '',
      videoHeight: '',
      nowPage: 1,
      totalPages: 1,
      recommendData: [],
      rating: 4,
      isLoggedin: false,
      videoStatus: false,
      course: [],
      chapterContent: '',
      totalChapter: 0,
      totalSection: 0,
      nowChapter: 0,
      nowSection: 0,
      NowSectionNum: 0,
      swiperOption: {
        spaceBetween: 30,
        slidesPerView: 4,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next-1',
          prevEl: '.swiper-button-prev-1',
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          576: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          876: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      },
      editorOption: {
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold'],
              [{ list: 'ordered' }, { list: 'bullet' }], ['link', 'image'],
            ],
          },
        },
        theme: 'snow',
      },
      content: '',
      title: '',
      isOpen: false,
      vid: '',
      videoId: '',
      showNum: 100,
      chapterId: 0,
      chapters: [],
      chapterIdData: [],
      courseId: '',
      comments: [],
      commented: false,
      commentLoading: false,
      c_title: '',
      c_rating: 5,
      c_description: '',
      c_id: '',
      tempReply: '',
      discussion: [{
        id: 1,
        img: '',
        title: 'test title',
        content: 'content',
        time: '2022/5/20',
        username: 'qq',
        response: [
          {
            id: 1 - 1,
            img: '',
            content: 'content 1',
            time: '2022/5/20',
            username: 'qq',
          },
        ],
      },
      {
        id: 2,
        img: '',
        title: 'test title2',
        content: 'content',
        time: '2022/5/20',
        username: 'qq',
        response: [
          {
            id: 2 - 1,
            img: '',
            content: 'content 1',
            time: '2022/5/20',
            username: 'qq',
          },
        ],
      },
      ],
      track: true,
      metaTitle: '元學堂',
    };
  },
  metaInfo() {
    return {
      title: this.course.courseName,
      meta: [
        { name: 'description', content: this.course.courseDescription },
        {
          property: 'og:title',
          content: this.course.courseName,
        },
        {
          property: 'og:description',
          content: this.course.courseDescription,
        },
      ]
    }
  },
  methods: {
    console1() {
      this.$log.debug('loaded');
      const vm = this;
      let sec;
      this.$http.get(`${this.$API_PATH}/Admin/UserRoles/${this.videoId}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        sec = res.data.data;
        this.$log.debug(this.videoId, sec);
        return vm.$refs.player.player.setCurrentTime(sec);
      }).then((res) => {
        this.$log.debug('set time');
      }).catch((err) => {
        this.$log.debug(err.response);
      });
      this.$refs.player.player.setCurrentTime(0.000).then((seconds) => {
        // `seconds` indicates the actual time that the player seeks to
      }).catch((error) => {
        // this.$log.debug(err);
      });
    },
    changeVideo(chapter) {
      this.$log.debug(chapter.nowChapter);
      const vm = this;
      // if(this.videoStatus !== 'available') {
      //   return;
      // }
      vm.getCurrentTime().then((res) => {
        this.$log.debug(res);
        vm.videoId = chapter.videoId;
        vm.nowChapter = Number(chapter.nowChapter.split('-')[0]);
        vm.nowSection = Number(chapter.nowChapter.split('-')[1]);
        vm.vid = chapter.url;
        vm.chapterId = chapter.chapterId;
        vm.chapterContent = chapter.content;
        vm.videoStatus = chapter.status;
        vm.NowSectionNum = vm.getNowSectionNum();
        vm.$log.debug('change video');
      }).catch((err) => {
        vm.$log.debug(err);
      });
      // call watched history api
    },
    showReply(num) {
      this.showNum = num;
      this.tempReply = '';
    },
    getData() {
      this.$http.get(`${this.$API_PATH}/Course/Selected?Take=6`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.$log.debug(res.data.data.selectedCourseList);
        this.recommendData = res.data.data.selectedCourseList.slice(0, 5);
      }).catch((err) => {
        // this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    getComments() {
      const vm = this;
      this.commentLoading = true;
      this.$http.get(`${this.$API_PATH}/Course/Comment?CourseId=${this.courseId}&Page=${this.nowPage}`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.commentLoading = false;
        this.comments = res.data.data.courseCommentList;
        this.totalPages = Math.ceil(res.data.data.total / 6);
        vm.$log.debug(this.totalPages);
        let prevComment = this.comments.filter((item, i) => {
          if (item.userId === this.userId) {
            // this.$store.commit('SETCOMMENTMODE', true);
            vm.commented = true;
            return item;
          }
        });
        vm.$log.debug(this.commented);
        prevComment = prevComment[0];
        this.c_title = prevComment.title;
        this.c_rating = prevComment.starRating;
        this.c_description = prevComment.comment;
        this.c_id = prevComment.id;

        vm.$log.debug(this.commented);
      }).catch((err) => {
        this.commentLoading = false;
        // this.$router.push('/error');
        vm.$log.debug(err.response);
      });
    },
    putComment(comment) {
      if (this.commented == true) {
        this.$http.put(`${this.$API_PATH}/Course/Comment`, comment, {
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `bearer ${this.userAuth}`,
          },
        }).then((res) => {
          this.$log.debug(res.data);
          Swal.fire(
            '',
            `${res.data.message}`,
            'success',
          );
          this.nowPage = 1;
          this.getComments();
        }).catch((err) => {
          if (err.response.status == 400 || err.response.status == 401) {
            Swal.fire(
              '',
              `${res.data.message}`,
              'info',
            );
          }
          this.$log.debug(err.response);
        });
      }
    },
    submit() {
      if (this.commented) {
        const comment = {
          title: this.c_title,
          starRating: this.c_rating,
          comment: this.c_description,
          id: this.c_id,
        };
        this.putComment(comment);
      } else {
        const comment = {
          Title: this.c_title,
          StarRating: this.c_rating,
          Comment: this.c_description,
          CourseId: this.courseId,
        };
        this.submitComment(comment);
      }
    },
    submitComment(comment) {
      this.$log.debug(comment);
      this.$http.post(`${this.$API_PATH}/Course/Comment`, comment, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data);
        Swal.fire(
          '',
          `${res.data.message}`,
          'success',
        );
        this.nowPage = 1;
        this.getComments();
      }).catch((err) => {
        if (err.response.status == 400 || err.response.status == 401) {
          Swal.fire(
            '',
            `${res.data.message}`,
            'info',
          );
        }
        this.$log.debug(err.response);
      });
    },
    addRes() {
      let i = -1;
      this.discussion.forEach((item, idx) => {
        if (item.id == this.showNum) {
          i = idx;
        }
      });
      this.discussion[i].response.push({
        id: Math.floor(Date.now()),
        img: '',
        content: this.tempReply,
        time: '2022/5/20',
        username: 'qq',
      });
    },
    switchPage(num) {
      this.nowPage += num;
      if (this.nowPage < 1) {
        this.nowPage = 1;
      }
      if (this.nowPage > this.totalPages) {
        this.nowPage = this.totalPages;
      }
      this.getComments();
    },
    checkCommented() {
      const vm = this;
      for (let i = 1; i < this.totalPages + 1; i++) {
        this.$http.get(`${this.$API_PATH}/Course/Comment?CourseId=${this.courseId}&Page=${i}`, {
          headers: {
            Accept: '*/*',
          },
        }).then((res) => {
          this.totalPages = Math.ceil(res.data.data.total / 6);
          vm.$log.debug(this.totalPages);
          const prevComment = res.data.data.courseCommentList.filter((item, i) => {
            if (item.userId === this.userId) {
              // this.$store.commit('SETCOMMENTMODE', true);
              vm.commented = true;
              return item;
            }
          });
          vm.$log.debug(this.commented);
        }).catch((err) => {
          this.$router.push('/error');
          vm.$log.debug(err.response);
        });
      }
    },
    getCourse() {
      this.$http.get(`${this.$API_PATH}/Course/Chapter?Id=${this.courseId}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.chapterIdData = res.data.data;
        return this.$http.get(`${this.$API_PATH}/Course?CourseId=${this.courseId}`, {
          headers: {
            Accept: '*/*',
            Authorization: `bearer ${this.userAuth}`,
          },
        });
      }).then((res) => {
        if (res.data.data.chapter.length > 0) {
          this.course = res.data.data;
          if (!this.course.purchased) {
            this.$router.replace(`/course/${this.course.courseId}`);
          }
          const arr = Array.from(res.data.data.chapter);
          this.metaTitle = `${this.course.courseName} - 元學堂`;
          this.chapterIdData.forEach((item, i) => {
            item.subChapter.forEach((el, idx) => {
              arr[i].chaperItem[idx] = { ...arr[i].chaperItem[idx], chapterId: el.id, completed: false };
            });
          });

          this.vid = arr[0].chaperItem[0].videoUrl;
          this.videoId = arr[0].chaperItem[0].videoId;
          this.chapterId = arr[0].chaperItem[0].chapterId;
          this.chapterContent = arr[0].chaperItem[0].content;
          this.videoStatus = arr[0].chaperItem[0].videoStatus;
          let chapter = 0;
          let section = 0;
          arr.forEach((item) => {
            chapter++;
            item.chaperItem.forEach((el) => {
              section++;
            });
          });
          this.totalChapter = chapter;
          this.totalSection = section;
          this.$log.debug(arr);
          this.arr = arr;
          document.documentElement.style.setProperty('--c-main', `url(${this.course.coursePrevImgUrl1})`);
          return this.$http.get(`${this.$API_PATH}/Member/CourseProgress/${this.courseId}`,
            {
              headers: {
                Accept: '*/*',
                Authorization: `bearer ${this.userAuth}`,
              },
            });
        }
      }).then((res) => {
        this.$log.debug(this.arr);
        this.$log.debug(res.data.data.courseProgresss);

        res.data.data.courseProgresss.forEach((item, idx) => {
          if (item.completed) {
            this.endChapterId.push(item.chapterId);
          }
          this.arr.forEach((el, i) => {
            el.chaperItem.forEach((element, n) => {
              if (element.chapterId === item.chapterId) {
                element.completed = item.completed;
              }
            });
          });
        });
        this.chapters = [...this.arr];
        this.$log.debug(this.chapters);
        this.NowSectionNum = this.getNowSectionNum();
      })
        .catch((err) => {
        // this.$router.push('/error');
          this.$log.debug(err.response);
        });
    },
    getNowSectionNum() {
      this.$log.debug(this.chapters[this.nowChapter].chaperItem.length);
      return this.chapters[this.nowChapter].chaperItem.length;
    },
    setChapterComplete() {
      const chapterProgress = {
        courseId: this.courseId,
        courseChapterId: this.chapterId,
      };
      if (this.chapters[this.nowChapter].chaperItem[this.nowSection].completed) {
        return;
      }
      this.endChapterId.push(this.chapterId);
      this.$http.post(`${this.$API_PATH}/Member/CourseProgress`, chapterProgress, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        // this.$set(this.chapters[this.nowChapter].chaperItem[this.nowSection], 'completed', true);
        this.$log.debug(res.data);
      }).catch((err) => {
        this.$log.debug(err.response);
      });
    },
    setTime(data) {
      this.$log.debug(this.vid, this.videoId, data.seconds, data.percent);
      const query = {
        resumePoint: Math.round(data.seconds),
      };
      if (this.track && data.seconds !== null) {
        this.$http.post(`${this.$API_PATH}/Admin/UserRoles/${this.videoId}`, query, {
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `bearer ${this.userAuth}`,
          },
        }).then((res) => {
          this.$log.debug(res.data);
        }).catch((err) => {
          this.$log.debug(err.response);
        });
      }
    },
    next() {
      if (this.nowChapter == (this.totalChapter - 1) && this.nowSection == (this.NowSectionNum - 1)) {
        return;
      } if (this.nowSection == (this.NowSectionNum - 1)) {
        this.nowChapter += 1;
        this.nowSection = 0;
        this.NowSectionNum = this.getNowSectionNum();
      } else {
        this.nowSection += 1;
        this.NowSectionNum = this.getNowSectionNum();
      }
      this.chapterId = this.chapters[this.nowChapter].chaperItem[this.nowSection].chapterId;
      this.vid = this.chapters[this.nowChapter].chaperItem[this.nowSection].videoUrl;
      this.videoId = this.chapters[this.nowChapter].chaperItem[this.nowSection].videoId;
      this.chapterContent = this.chapters[this.nowChapter].chaperItem[this.nowSection].content;
      this.videoStatus = this.chapters[this.nowChapter].chaperItem[this.nowSection].videoStatus;
    },
    prevAndNext(num) {
      const vm = this;
      if (vm.videoStatus !== 'available') {
        return;
      }
      vm.getCurrentTime().then((res) => {
        this.$log.debug(res);
        if (vm.nowSection == (vm.NowSectionNum - 1) && num == 1) {
          if (vm.nowChapter == (vm.totalChapter - 1)) {
            return;
          }
          vm.nowChapter += num;
          vm.nowSection = 0;
          vm.NowSectionNum = vm.getNowSectionNum();
        } else if (vm.nowSection == 0 && num == -1) {
          if (vm.nowChapter == 0) {
            return;
          }
          vm.nowChapter += num;
          vm.NowSectionNum = vm.getNowSectionNum();
          vm.nowSection = vm.NowSectionNum - 1;
        } else {
          vm.nowSection += num;
          vm.NowSectionNum = vm.getNowSectionNum();
        }
        this.chapterId = this.chapters[this.nowChapter].chaperItem[this.nowSection].chapterId;
        vm.vid = vm.chapters[vm.nowChapter].chaperItem[vm.nowSection].videoUrl;
        vm.videoId = vm.chapters[vm.nowChapter].chaperItem[vm.nowSection].videoId;
        vm.chapterContent = vm.chapters[vm.nowChapter].chaperItem[vm.nowSection].content;
        vm.videoStatus = vm.chapters[vm.nowChapter].chaperItem[vm.nowSection].videoStatus;
      }).catch((err) => {
        vm.$log.debug(err);
      });
    },
    getCurrentTime() {
      const vm = this;
      return vm.$refs.player.player.getCurrentTime().then((seconds) => {
        const query = {
          resumePoint: Math.round(seconds),
        };
        if (vm.track && seconds !== null) {
          return vm.$http.post(`${this.$API_PATH}/Admin/UserRoles/${vm.videoId}`, query, {
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              Authorization: `bearer ${vm.userAuth}`,
            },
          });
        }
        return new Promise((resolve) => {
          resolve('resolve track');
        });
      });
    },
    getTrackOrNot() {
      this.$http.get(`${this.$API_PATH}/Member/VideoSetting`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data.data);
        this.track = res.data.data.resumeEnable;
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    decodeEntity(inputStr) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = inputStr;
      return textarea.value;
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth']),
  },
  watch: {
    track(newVal, oldVal) {
      this.$log.debug(newVal);
      const data = {
        resumeEnable: newVal,
      };
      this.$http.put(`${this.$API_PATH}/Member/VideoSetting`, data, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data.data);
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
  },
  mounted() {
    const vm = this;
    window.addEventListener('beforeunload', function (e) {
      if (this.videoStatus == 'available') {
        vm.getCurrentTime().then((res) => {
          this.$log.debug(res);
        }).catch((err) => {
          vm.$log.debug(err);
        });
      }
    });
  },
  destroyed() {
    const vm = this;
    window.removeEventListener('beforeunload', function (e) {
      if (this.videoStatus == 'available') {
        vm.getCurrentTime().then((res) => {
          this.$log.debug(res);
        }).catch((err) => {
          vm.$log.debug(err);
        });
      }
    });
  },
  computed: {
    splitVid() {
      return this.vid.split('/')[4].split('?')[0];
    },
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  beforeRouteLeave(to, from, next) {
    const vm = this;
    this.$log.debug(this.videoStatus);
    if (to.name == 'Login' || to.name == 'Error' || to.name == 'Course') {
      next();
    } else if (this.videoStatus !== 'available') {
      next();
    } else {
      vm.getCurrentTime().then((res) => {
        this.$log.debug(res);
        next();
      }).catch((err) => {
        vm.$log.debug(err);
      });
    }
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    this.$log.debug(this.userAuth);
    if (this.userAuth.length == 0) {
      this.isLoggedin = false;
      this.$router.push('/login');
    } else {
      this.isLoggedin = true;
    }
    this.getTrackOrNot();
    this.getData();
    this.courseId = this.$route.params.vid;
    this.getCourse();
    this.getComments();

    this.$http.get(`${this.$API_PATH}/Course/Comment?CourseId=${this.courseId}&Page=${this.nowPage}`, {
      headers: {
        Accept: '*/*',
      },
    }).then((res) => {
      this.totalPages = Math.ceil(res.data.data.total / 6);
      const prevComment = res.data.data.courseCommentList.filter((item, i) => {
        if (item.userId === this.userId) {
          // this.$store.commit('SETCOMMENTMODE', true);
          this.commented = true;
          return item;
        }
      });
      return this.checkCommented();
    }).catch((err) => {
      this.commentLoading = false;
      // this.$router.push('/error');
      this.$log.debug(err.response);
    });
  },
};
// end, last sec => post CourseProgress
</script>

<style lang="scss" scoped>
.video__wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}

.video__wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.course__qa ::v-deep .ql-container {
  height: calc(100% - 60px);
}

.ql-editor {
  overflow: visible;
}

</style>
